<template>
	<div class="px-3 pb-6 text-xs text-gray-500 border-gray-200 md:p-3 md:border-t-2">
		<div class="justify-between w-full max-w-6xl m-auto md:flex">
			<div>
				<router-link
					class="hover:text-green-500"
					to="/privacy"
				>
					Privacy Policy
				</router-link>
				|
				<router-link
					class="hover:text-green-500"
					to="/terms"
				>
					Terms of Service
				</router-link>
				|
				<router-link
					class="hover:text-green-500"
					to="/copyright"
				>
					Copyright Policy
				</router-link>
			</div>
			<div>
				&copy; Copyright 2012-{{ year }} {{ APP_META.DOMAIN_NAME }}. All rights reserved.
			</div>
		</div>
	</div>
</template>

<script defer>
import { APP_META } from '@/constants/index.js'

export default {
	data() {
		return {
			APP_META
		}
	},
	computed: {
		year() {
			return new Date().getFullYear()
		}
	}
}
</script>
